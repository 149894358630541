@import '@aurora/shared-client/styles/_variables.pcss';

.lia-container {
  padding: 20px 30px 0 20px;

  .lia-content-filters {
    :global {
      .diff-html-removed {
        background-color: var(--lia-rte-diff-changed-color);
        text-decoration: line-through;
      }

      .diff-html-removed img {
        width: 100%;
        border: 8px solid var(--lia-rte-diff-changed-color);
        border-radius: var(--lia-bs-border-radius-sm);
      }
    }
  }
}

.lia-compare {
  &-container-body {
    max-width: var(--lia-one-column-narrow-width);
    width: 100%;
    margin: 0 auto;
    min-height: 100%;

    :global {
      .diff-html-none {
        background-color: var(--lia-rte-diff-none-color);
      }

      .diff-html-none img {
        width: 100%;
        border: 8px solid var(--lia-rte-diff-none-color);
        border-radius: var(--lia-bs-border-radius-sm);
      }

      .diff-html-blocked {
        background-color: var(--lia-rte-diff-removed-color);
      }

      .diff-html-blocked img {
        width: 100%;
        border: 8px solid var(--lia-rte-diff-removed-color);
        border-radius: var(--lia-bs-border-radius-sm);
      }

      .diff-html-added {
        background-color: var(--lia-rte-diff-added-color);
      }

      .diff-html-added img {
        width: 100%;
        border: 8px solid var(--lia-rte-diff-added-color);
        border-radius: var(--lia-bs-border-radius-sm);
      }

      .diff-html-removed {
        background-color: var(--lia-rte-diff-removed-color);
        text-decoration: line-through;
      }
      .diff-html-removed img {
        width: 100%;
        border: 8px solid var(--lia-rte-diff-removed-color);
        border-radius: var(--lia-bs-border-radius-sm);
      }
      .diff-html-changed {
        background-color: var(--lia-rte-diff-changed-color);
      }
      .diff-html-changed img {
        width: 100%;
        border: 8px solid var(--lia-rte-diff-changed-color);
        border-radius: var(--lia-bs-border-radius-sm);
      }
      blockquote {
        padding: 20px 20px 20px 30px;
        background-color: var(--lia-bs-gray-200);
        border-radius: var(--lia-bs-border-radius-sm);
        border-left: 5px solid var(--lia-bs-primary);
        margin-top: 40px;
        clear: both;
      }
    }
    @media (--lia-breakpoint-down-md) {
      width: 100%;
    }
  }
  &-summary {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid var(--lia-bs-border-color);
    color: var(--lia-bs-gray-900);
    font-weight: var(--lia-bs-font-weight-normal);
    display: flex;
    width: 100%;
    flex-direction: column;

    &-title {
      margin-bottom: 10px;
      font-weight: var(--lia-bs-font-weight-bold);
    }
  }
}

.lia-author-login {
  font-weight: var(--lia-bs-font-weight-bold);
  color: var(--lia-bs-body-color);
  &:hover {
    color: var(--lia-bs-body-color);
  }
}
.lia-filter-tag {
  padding: 5px;
  word-break: break-all;
  margin-right: 5px;
  margin-top: 20px;
  letter-spacing: 0.5px;
  color: var(--lia-bs-body-color);
  overflow-wrap: break-word;
  font-size: var(--lia-font-size-xxs);
  font-weight: var(--lia-font-weight-md);
  line-height: 1.09090909;
  overflow: hidden;
  white-space: normal;
  text-align: left;
}
.lia-filter-timestamp {
  font-size: var(--lia-bs-font-size-sm);
}
